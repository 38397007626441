import * as React from 'react';
import { KultunautEvent } from '../shared/KultunautTypes';
import { getDateRangeString } from '../globals/kultunaut-date-lib';
import { colors } from '../globals/colors';
import hooks from '../globals/hooks';


export const SmallEventView: React.FC<{ event: KultunautEvent }> = ({ event }) => {



    const dateString = getDateRangeString(event)

    return (
        <div className='hoverable' style={{ display: 'flex', flexDirection: 'row', marginTop: 12 }} onClick={() => hooks.handleEventClick(event.Id)} >
            <div style={{ width: 200, minHeight: 130, backgroundImage: "url(" + event.Image + ")", backgroundPosition: 'center', backgroundSize: 'cover' }} />
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 12, paddingTop: 6, paddingBottom: 6, backgroundColor: colors.white, width: '100%', borderEndEndRadius: 25 }}>
                <h4 style={{ marginTop: 0 }}>{event.Title}</h4>
                <span style={{fontSize: 14}}>{dateString}</span>
                <span>{event.LocationName}</span>
            </div>
        </div>
    )
}