import * as React from 'react';
import { useParams } from 'react-router-dom';
import hooks from '../../globals/hooks';
import { KultunautEvent } from '../../shared/KultunautTypes';
import { getDateRangeString } from '../../globals/kultunaut-date-lib';
import { colors } from '../../globals/colors';
import { SmallEventView } from '../../components/SmallEventView';

const apple = require('../../assets/apple.png')
const google = require('../../assets/google.png')

export const EventPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const [loading, setLoading] = React.useState(true)
  const [event, setEvent] = React.useState<KultunautEvent>()
  const [venueEvents, setVenueEvents] = React.useState<KultunautEvent[]>([])
  const [similarEvents, setSimlilarEvents] = React.useState<KultunautEvent[]>([])

  const init = async () => {
    setLoading(true)
    const result = await hooks.getEventById(eventId)
    if (!result) {
      setTimeout(function () { document.location.href = "https://www.gogoo.app/" }, 250)
    } else {
      setEvent(result)
      if (result.venueEvents) setVenueEvents(result.venueEvents)
      if (result.similarEvents) setSimlilarEvents(result.similarEvents)
    }
    setLoading(false)
  }
  React.useEffect(() => { init() }, [])



  if (!event || loading) return (
    <div className='container' style={{ textAlign: 'center' }}>
      <h1>Henter data...</h1>
    </div>

  )

  const textHidingSize = event.Shortdescription && event.Shortdescription?.length > 100 ? 120 : 40

  const dateString = getDateRangeString(event)

  return (
    <div>
      <div className='container'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h1>{event.Title}</h1>
          <span><b>{dateString}</b></span>
          <span>{event.LocationName} - {event.LocationAddress}, {event.LocationZip} {event.LocationCity}</span>
          <div style={{ justifyContent: 'center', display: 'flex', marginTop: "1em" }}>
            <img src={event.Image} alt={event.Title} style={{ maxWidth: '100vw' }} />
          </div>
          <p>{event.Shortdescription}</p>
          <div style={{ marginTop: -textHidingSize, display: 'flex', justifyContent: 'center', backgroundImage: "linear-gradient(transparent, " + colors.backgroud + ", " + colors.backgroud + ")", height: textHidingSize }}>
          </div>
          <button onClick={() => hooks.handleEventClick(event.Id)} style={{ marginBottom: 40, marginTop: -40, marginLeft: 'auto', marginRight: 'auto', paddingLeft: 80, paddingRight: 80 }}>Læs mere på gogoo</button>

        </div>
      </div>
      <div className='container' style={{ padding: 12, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <a target='_blank' href='https://apps.apple.com/dk/app/gogoo/id1560958137?l=da&utm_source=gogoo&utm_campaign=dfed79c663-EMAIL_CAMPAIGN_2021_05_06_12_01_COPY_02&utm_medium=email&utm_term=0_74d97818bd-dfed79c663-439453846'>
          <img src={apple} width={160} alt='apple' />
        </a>
        <a target='_blank' href='https://play.google.com/store/apps/details?id=app.gogoo.gogoo'>
        <img src={google} width={160} alt='google' />

        </a>
      </div>
      <div className='container' style={{ padding: 0 }}>
        {venueEvents && venueEvents.length > 0 && (
          <div style={{ marginTop: 50 }}>
            <h3 style={{ padding: 10 }}>Mere fra {event.LocationName}</h3>
            {venueEvents.map((item, key) => <SmallEventView event={item} key={key} />)}
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <button onClick={() => hooks.handleEventClick(event.Id)} style={{ marginBottom: 40, marginTop: 20, marginLeft: 'auto', marginRight: 'auto', paddingLeft: 80, paddingRight: 80 }}>Se mere fra {event.LocationName} på gogoo</button>
            </div>

          </div>
        )}
        {similarEvents && similarEvents.length > 0 && (
          <div style={{ marginTop: 50 }}>
            <h3 style={{ padding: 10 }}>Lignende events</h3>
            {similarEvents.map((item, key) => <SmallEventView event={item} key={key} />)}
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <button onClick={() => hooks.handleEventClick(event.Id)} style={{ marginBottom: 40, marginTop: 20, marginLeft: 'auto', marginRight: 'auto', paddingLeft: 80, paddingRight: 80 }}>Se flere på gogoo</button>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}