import { KultunautEvent } from "../shared/KultunautTypes"

function formatTime(timeStr: string) {
    // Remove "kl." and any spaces
    timeStr = timeStr.replace("kl.", "").trim();

    // Split on any hyphen or dash to get the start time
    let startTime = timeStr.split('-')[0].trim();

    // Replace any "." with ":"
    startTime = startTime.replace(".", ":");

    // If there's no ":", it's a whole hour, so append ":00"
    if (!startTime.includes(":")) {
        startTime += ":00";
    }

    // If there's only one digit for the hour, prepend a "0"
    if (startTime.length === 4) {
        startTime = "0" + startTime;
    }

    return startTime;
}

export const kultuanutDateToNodeDate = (date: string, time?: string): Date => {
    if (!date) return new Date()
    const dateArray = date.split('-')
    if (isNaN(Number(dateArray[0])) || isNaN(Number(dateArray[1])) || isNaN(Number(dateArray[2]))) {
        return new Date()
    }
    const nodeDate = new Date()
    nodeDate.setDate(Number(dateArray[0]))
    nodeDate.setMonth(Number(dateArray[1]) - 1)
    nodeDate.setFullYear(Number(dateArray[2]))

    if (time) {
        const fomrattedTime = formatTime(time)
        if (fomrattedTime.includes(':')) {
            const timeArray = fomrattedTime.split(':')
            nodeDate.setMinutes(Number(timeArray[1]))
            nodeDate.setHours(Number(timeArray[0]))
        } else {
            nodeDate.setMinutes(59)
            nodeDate.setHours(23)
        }
    }
    return nodeDate
}

export const nodeEnhancedDateToKultunautdate = (date: (Date | number | undefined)): (string | undefined) => {
    if (!date) return undefined
    if (date instanceof Date) {
        return nodeDateToKultunautdate(date)
    } else {
        return nodeDateToKultunautdate(new Date(date))
    }
}

export const getAverageDateFromKultunautEvent = (kultunautEvent: KultunautEvent): Date => {
    const start = kultuanutDateToNodeDate(kultunautEvent.Startdate)
    const end = kultuanutDateToNodeDate(kultunautEvent.Enddate)
    return new Date((end.getTime() + start.getTime()) / 2)
}

const isToday = (date: Date): boolean => {
    const today = new Date()
    return date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()
}

const isTomorrow = (date: Date): boolean => {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)    
    return date.getDate() == tomorrow.getDate() && date.getMonth() == tomorrow.getMonth() && date.getFullYear() == tomorrow.getFullYear()
}

export const getDateRangeString = (kultunautEvent: KultunautEvent, shorten?: boolean): string => {
    const Enddate = kultunautEvent.Enddate
    const Startdate = kultunautEvent.Startdate
    const sameDay = (!Enddate || Enddate === Startdate)
    const suffix = (kultunautEvent.Time? " "+kultunautEvent.Time :"")
    if (sameDay && isToday(kultuanutDateToNodeDate(Startdate))) return "I dag"+suffix
    if (sameDay && isTomorrow(kultuanutDateToNodeDate(Startdate)))return "I morgen"+suffix

    if(sameDay) return Startdate + suffix
    const shortStartDate = Startdate.slice(0, Startdate.length-5)
    const shortEndDate = Enddate.slice(0, Enddate.length-5)

    if(shorten) return shortStartDate+ ' til ' + Enddate ;
    return shortStartDate+ ' til ' + Enddate + suffix ;
}

export const nodeDateToKultunautdate = (date: Date): string => {
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
}

export const kultuanutDateToNumber = (date: string, time?: string): number => {
    const d = kultuanutDateToNodeDate(date, time)
    return d.getTime()
}